import React, { useEffect } from 'react'
import { GetServerSideProps, NextPage } from 'next'
import Router from 'next/router'
import { getTokens } from 'server/auth'

type Props = {
  to: string
}

const Page: NextPage<Props> = ({ to }) => {
  useEffect(() => {
    Router.push(to)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <></>
}

export const getServerSideProps: GetServerSideProps = async ({ req }) => {
  const { authToken } = getTokens(req.headers.cookie)
  const to = authToken == null ? '/signup' : '/mypage'

  return {
    props: { to },
  }
}

export default Page
